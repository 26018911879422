import { useKeenSlider } from 'keen-slider/react'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { useTranslate } from '/machinery/I18n'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Subvakgebieden.css'

import arrowIcon from '/images/icons/arrow.raw.svg'

export function Subvakgebieden({ title, text, subvakgebieden, vakgebied }) {
  const isViewportXxl = useMediaQuery(mediaStyles.viewportXxl)

  return (
    <div className={styles.component}>
      <ContainerLg>
        <div className={styles.inner}>
          <div className={styles.content}>
            <HeadingMd layoutClassName={styles.headingLayout} h={2} {... { title }} />
            {text && <PortableTextMainContent value={text} />}
          </div>
          {subvakgebieden && (
            <div className={styles.subvakgebieden}>
              {isViewportXxl
                ? (
                  <Grid>
                    {subvakgebieden.map(({ title, subtitle, slug, _id }) => (
                      <Card
                        key={_id}
                        url={routeMap.app.vakgebied.subvakgebied({ language: vakgebied.language, vakgebiedTitle: vakgebied.slug.current, slug: slug.current })}
                        {... { title, subtitle }}
                      />
                    ))}
                  </Grid>
                ) : (
                  <Slider>
                    {subvakgebieden.map(({ title, subtitle, slug, _id }) => (
                      <div key={_id} className={cx(styles.slide, 'keen-slider__slide')}>
                        <Card
                          url={routeMap.app.vakgebied.subvakgebied({ language: vakgebied.language, vakgebiedTitle: vakgebied.slug.current, slug: slug.current })}
                          {... { title, subtitle }}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
            </div>
          )}
        </div>
      </ContainerLg>
    </div>
  )
}

function Slider({ children }) {
  const [sliderRef] = useKeenSlider(
    {
      slides: {
        perView: 1.2,
        spacing: 16
      },
      slideChanged(s) {
        trackInteraction(s, { action: 'slided' })
      },
      dragStarted(s) {
        trackInteraction(s, { action: 'drag-started' })
      },
      dragEnded(s) {
        trackInteraction(s, { action: 'drag-ended' })
      },
      breakpoints: {
        [`(min-width: ${mediaStyles.breakpointSm})`]: {
          slides: {
            spacing: 16,
            perView: 1.8
          }
        },
        [`(min-width: ${mediaStyles.breakpointMd})`]: {
          slides: {
            spacing: 16,
            perView: 3.2
          }
        },
        [`(min-width: ${mediaStyles.breakpointLg})`]: {
          slides: {
            spacing: 16,
            perView: 3.8
          }
        },
      },
    }
  )

  return (
    <div ref={sliderRef} className={cx(styles.componentSlider, 'keen-slider')}>
      {children}
    </div>
  )

  function trackInteraction(s, { action }) {
    pushToDataLayer({
      event: 'interaction',
      metadata: {
        interaction: { index: s.track.details.rel, type: 'slider', action }
      }
    })
  }
}

function Grid({ children }) {
  return (
    <div className={styles.componentGrid}>
      {children}
    </div>
  )
}

function Card({ title, subtitle, url }) {
  const { __ } = useTranslate()
  const [hovered, setHovered] = React.useState(false)

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={styles.componentCard}
      data-style-context='light'
    >
      <article className={styles.container}>
        <header className={styles.headerCard}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.intro}><p>{subtitle}</p></div>
        </header>

        <div className={styles.bottom}>
          <a data-x='link-to-subvakgebied' className={cx(styles.link, hovered && styles.visible)} href={url}>{__`lees-meer`}</a>
          <div className={styles.icon}><Icon icon={arrowIcon} /></div>
        </div>
      </article>
    </div>

  )
}
